import React from 'react'
import './Marcas.css'
import marcas from '../images/marcas.png'
import marcasm from '../images/marcasm.png'

export const Marcas = () => {
    return (
        <div className="marcas">
            <img className="marcas__pc" src={marcas} alt="marcas" />
            <img className="marcas__m" src={marcasm} alt="marcasm" />
        </div>
    )
}
