import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Marcas } from '../components/Marcas'

const NuestrasMarcas = () => {
    return (
        <Layout>
            <SEO title="Marcas" />
            <Marcas />
        </Layout>
    )
}
export default NuestrasMarcas
